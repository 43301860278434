import { QUESTION_HANDLER } from '../../constants/QuestionHandler';

import { ButtonHandler } from './ButtonHandler';
import { FriendHandler } from './FriendHandler';
import { CheckboxHandler } from './CheckboxHandler';
import { FormHandler } from './FormHandler';
import { RadioHandler } from './RadioHandler';
import { SliderHandler } from './SliderHandler';
import { BelbinTestHandler } from './BelbinTestHandler';

const questionHandlerMap = {
  [QUESTION_HANDLER.BUTTON]: ButtonHandler,
  [QUESTION_HANDLER.FRIEND]: FriendHandler,
  [QUESTION_HANDLER.CHECKBOX]: CheckboxHandler,
  [QUESTION_HANDLER.FORM]: FormHandler,
  [QUESTION_HANDLER.RADIO]: RadioHandler,
  [QUESTION_HANDLER.SLIDER]: SliderHandler,
  [QUESTION_HANDLER.BELBIN_TEST]: BelbinTestHandler,
};

export default questionHandlerMap;
