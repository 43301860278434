import { Title, useAppearance, Div } from '@vkontakte/vkui';
import BarChart from '../../common/charts/BarChart/BarChart';
import styles from './ScaleHandler.module.css';
import { ScaleHandlerProps } from './types';

export const ScaleHandler: React.FC<ScaleHandlerProps> = ({ factors, header }) => {
  const appearance = useAppearance();
  return (
    <Div className={styles.container}>
      <div className={styles.titleContainer}>
        <Title className={styles.title}>
          {header}
        </Title>
      </div>
      <div className={styles.barChatContainer}>
        <BarChart 
          data={factors} 
          formatKey={(key) => key}
          appearance={appearance}
        />
      </div>
    </Div>
  );
};
