import {
  Button,
  Paragraph,
  useAppearance
} from '@vkontakte/vkui';

import styles from './BelbinTestHandler.module.css';
import { BelbinTestHandlerProps } from './types';

export const BelbinTestHandler: React.FC<BelbinTestHandlerProps> = ({
  statementOptionClickHandler,
  moveForwardClickHandler,
  answerGroup,
  getOptionState
}) => {

  const appearance = useAppearance();

  const setOptionStyle = (index: number, value: number) => {
    switch(getOptionState(index, value)){
      case 'untouchable':
        return appearance === 'light' ? styles.questionOptionUntouchedLight : styles.questionOptionUntouchedDark;
      case 'touchable':
        return appearance === 'light' ? styles.questionOptionLight : styles.questionOptionDark;
      case 'touched':
        return appearance === 'light' ? styles.questionOptionTouchedLight : styles.questionOptionTouchedDark;
    }
  };

  const renderStatments = (index: number) => {
    return (
      Array.from(Array(11).keys())).map((item) => {
        return (
          <div 
            onClick={() => statementOptionClickHandler(index, item)}
            className={setOptionStyle(index, item)}
          >
            <span className={styles.block}>
              {item}
            </span>
          </div>
        );
      }
    );
  };

  return (
    <div>
      {answerGroup.map((possibleAnswer, index) => {
        return (
          <div className={styles.statementContainer}>
            <Paragraph>
              {possibleAnswer.content}
            </Paragraph>
            <div className={styles.optionContainer}>
              {renderStatments(index)}
            </div>
          </div>
        );
      })}
      <div className={styles.buttonContainer}>
        <Button 
          onClick={moveForwardClickHandler}
          className={styles.button}
          size='l'
        >
          Дальше
        </Button>
      </div>
    </div>
  );
};
