export  const currentSum = (array: {score: number}[]): number => array.reduce((partialSum, a) => partialSum + a.score, 0);

export const isLessOrEqual = (number: number, array: {score: number}[]) => {
  let sum = 0;
  for (let i = 0; i < array.length; ++i) {
    if (array[i].score != 0) {
      sum++;
    }
  };

  return sum <= number;
};