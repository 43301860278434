export const OUTPUT_HANDLER = {
  DEFAULT: 'default',
  ERROR: 'error',
  CLARIFICATION_DESCRIPTION: 'clarification_description',
  ONE_FACTOR: 'one_factor',
  SITUATIONS_FRIENDS: 'situations_friends',
  // TODO: Выпилить
  GOLLAND: 'golland',
  SCALE: 'scale',
  // TODO: Выпилить
  MODAL: 'modal', // Используется для отображения результата в модальном окне
} as const;

export type OutputHandlerType = typeof OUTPUT_HANDLER[keyof typeof OUTPUT_HANDLER];
