export const QUESTION_HANDLER = {
  BUTTON: 'button',
  FRIEND: 'friend',
  CHECKBOX: 'checkbox',
  FORM: 'form',
  RADIO: 'radio',
  SLIDER: 'slider',
  BELBIN_TEST: 'belbinTest',
} as const;

export type QuestionHandlerType = typeof QUESTION_HANDLER[keyof typeof QUESTION_HANDLER];
