import BarChart from './BarChart';
import { useAppearance } from '@vkontakte/vkui';
import { ChartContianerProps } from './types';

const BarChartConatiner: React.FC<ChartContianerProps> = ({data, formatKey, minBarWidth, gap}) => {
  const appearance = useAppearance();
  return (
    <BarChart
      data={data}
      formatKey={formatKey}
      appearance={appearance} 
      minBarWidth={minBarWidth} 
      gap={gap}/>
  ); 
}

export default BarChartConatiner; 

