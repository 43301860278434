import React from 'react';

import {
  Group,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Spacing,
} from '@vkontakte/vkui';

import { Result } from '../../../../components/profile/Result';
import { ErrorHandler } from '../../../../components/outputHandlers/ErrorHandler';

import { PanelType } from '../../../../constants/Panel';
import { ResultItemType } from '../../../../types/result.type';
import { OUTPUT_HANDLER } from '../../../../constants/OutputHandler';

export type ResultPanelPropsType = {
  id: PanelType;
  results?: ResultItemType[];
  onBackClick: () => void;
};

export const ResultPanel: React.FC<ResultPanelPropsType> = ({
  id,
  results,
  onBackClick,
}) => {
  if (!results) {
    return null;
  }

  return (
    <Panel id={id}>
      <PanelHeader before={<PanelHeaderBack onClick={onBackClick}/>}>
        Результат
      </PanelHeader>
      <Group>
        {Boolean(results.length) ? (
          results.map((result, index) => {
            if (result.output === OUTPUT_HANDLER.MODAL) {
              return null;
            }

            return (
              <React.Fragment key={index}>
                <Result {...result} />
                {(index + 1) < results.length && (
                  <Spacing size={12} />
                )}
              </React.Fragment>
            );
          })
        ) : (
          <ErrorHandler/>
        )}
      </Group>
    </Panel>
  );
};
