import React from 'react';
import { connect, useSelector } from 'react-redux';

import { RootState } from '../../../store';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { fetchTest } from '../../../store/testSlice';
import {
  selectAppearance,
  selectIsDesktop,
} from '../../../store/settingsSlice/selectors';

import { useRouter } from '../../../hooks/useRouter';
import { usePopout } from '../../../hooks/usePopout';

import { PANEL } from '../../../constants/Panel';
import { POPOUT } from '../../../constants/Popout';

import { TestCardContainerProps } from './TestCard.types';
import { TestCard } from './TestCard';
import { selectHasSkippableQuestions, selectPercentInfoByTestShortName } from '../../../store/testsSlice/testsSelectors';

const TestCardContainer: React.FC<TestCardContainerProps> = (props) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { setPopout, resetPopout } = usePopout();

  const testShortName = props.test.short_name;

  const percentInfo = useSelector((state: RootState) => selectPercentInfoByTestShortName(state, testShortName));
  const hasSkippableQuestions = useSelector((state: RootState) => selectHasSkippableQuestions(state, testShortName));

  const setTest = async (shortName: string) => {
    setPopout(POPOUT.SMART_SPINNER);
    await dispatch(fetchTest(shortName));
    resetPopout();

    router.forward({ panel: PANEL.CURRENT_TEST });
  };

  return (
    <TestCard
      {...props}
      setTest={setTest}
      percentInfo={percentInfo}
      hasSkippableQuestions={hasSkippableQuestions}
    />
  );
};

function mapStateToProps(state: RootState) {
  return {
    isDesktop: selectIsDesktop(state),
    appearance: selectAppearance(state),
  };
}

export default connect(mapStateToProps)(TestCardContainer);
