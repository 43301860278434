import React, { useRef } from 'react';

import { classNames} from '@vkontakte/vkui';

import { useDimensions } from '../../../../hooks/useDimensions';

import { Bar } from './Bar';
import styles from './BarChart.module.css';
import { ChartProps } from './types';

const BarChart: React.FC<ChartProps> = ({
  data,
  formatKey,
  minBarWidth = 256,
  appearance,
  gap = 7
}) => {
  const containerDivRef = useRef<HTMLDivElement>(null);
  const containerWidth = useDimensions(containerDivRef,(divElement) => divElement.offsetWidth);
  const scores = data.map(obj => obj.score);

  const maxScore = Math.max(...scores);
  const minScore = Math.min(...scores);

  return (
    <div
      className={classNames(
        styles.container,
        gap === 'auto' ? styles.autoGap : styles.customGap
      )}
      style={{ gap: gap === 'auto' ? undefined : gap }}
      ref={containerDivRef}
    >
      {
        containerWidth && data.map(({ key, score }, index) => (
          <Bar
            appearance={appearance ? appearance : 'light'}
            type={index === 0 ? 'emphasized' : 'primary'}
            score={score}
            label={formatKey ? formatKey(key) : key}
            minBarWidth={minBarWidth}
            chartWidth={containerWidth}
            maxScoreInChart={maxScore}
            minScoreInChart={minScore}
          />
        ))
      }
    </div>
  );
};

export default BarChart;
