import api from './api';

import { SettingsType } from '../types/settings.type';

export type GetTestsQueryParams = {
  testsShortName?: string[];
  withQuestions?: boolean;
  withPercent?: boolean;
};

function getUser() {
  return api('users');
}

function saveSettings(settings: Partial<SettingsType>) {
  return api('users/save_settings', settings, { method: 'PUT' });
}

function acceptPrivacyPolicy() {
  return api('users/accept_privacy_policy', {}, { method: 'PUT' });
}

const usersApi = { getUser, saveSettings, acceptPrivacyPolicy };

export default usersApi;
