import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { BelbinTestHandler } from './BelbinTestHandler';
import { POPOUT } from '../../../constants/Popout';
import { setPopout } from '../../../store/popoutSlice';
import { QuestionHandlerPropsType } from '../../test/Answer/Answer';
import { OPTION_STATES } from './types';
import { currentSum, isLessOrEqual } from './utils';

const BelbinTestHandlerContainer: React.FC<QuestionHandlerPropsType> = ({
  possibleAnswers,
  answer,
  moveForward,
}) => {
  const dispatch = useDispatch();
  const [currentAnswers, setCurrentAnswers] = useState(
    possibleAnswers.map((item) => ({
        score: 0,
        ...item,
      }))
    );

  useEffect(() => {
    setCurrentAnswers(
      possibleAnswers.map((item) => ({
        score: 0,
        ...item,
      })));
  }, [possibleAnswers]);

  const isOptionTouchable = (answerIndex: number, score: number) => {
    if (!isLessOrEqual(4, currentAnswers) && currentAnswers[answerIndex].score == 0) {
      return false;      
    }

    return score + currentSum(currentAnswers) - currentAnswers[answerIndex].score <= 10; 
  };

  const statementOptionClickHandler = (index: number, value: number) => {
    if (!isOptionTouchable(index, value)) {
      return;
    }

    const newCurrentAnswers = currentAnswers.map((s, i) => {
      if (i === index){      
        return{
            ...s,
            score: value,   
        }
      }

      return s
    });

    setCurrentAnswers(newCurrentAnswers);
    if (currentSum(newCurrentAnswers) == 10 && isLessOrEqual(5, newCurrentAnswers)) {
      answer([newCurrentAnswers]);
    }
  };

  const getOptionState = (index: number, value: number): OPTION_STATES => {
    if (!isOptionTouchable(index, value)){
      return 'untouchable';
    };

    if (currentAnswers[index].score === value) {
      return 'touched';
    }

    return 'touchable';
  };

  const moveForwardClickHandler = () => {
    if (currentSum(currentAnswers) == 10 && isLessOrEqual(5, currentAnswers)) {
      answer([currentAnswers]);
      moveForward();
      return;
    }

    dispatch(setPopout({
      type: POPOUT.ERROR_SNACKBAR,
      meta_info: {
        message: 'Вы распределили не все баллы',
        duration: 2000,
      },
    }));
  };

  return (
    <BelbinTestHandler 
      answerGroup={possibleAnswers}
      statementOptionClickHandler={statementOptionClickHandler}
      moveForwardClickHandler={moveForwardClickHandler}
      getOptionState={getOptionState}
    />
  );
};

export default BelbinTestHandlerContainer;
